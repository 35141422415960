import { Card, ProgressBar } from '@blueprintjs/core';
import { Typography } from 'antd';
import { FormikProps, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  css,
  EyeClose,
  EyeOpen,
  Heading,
  InputField,
  Link,
  PhoneInputField,
  Text,
  TextField,
} from 'registration-flow-v2';
import { EValidationStatus } from 'registration-flow-v2/types';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { RegisterSchema } from 'utilities/form-utils';
import { workspaceRedirectionUrl, loginPath } from 'utilities/navigation';
import * as styles from 'views/css/css-components/RegisterForm.css';
import ErrorStateMessage from '../components/ErrorStateMessage';
import UnauthorizeMessage from '../components/UnauthorizeMessage';
import { INVITED_STEPS, PASSWORD_ERRORS } from '../constants';
import { IRegisterForm } from '../interfaces';
import { type CountryCode } from 'variables/locales';

type IRegisterFormProperties = {
  visibleSuccessScreen?: () => void;
  setStep?: (value: INVITED_STEPS) => void;
  setIsCircleFullScreen?: (value: boolean) => void;
  setIsLoadingForm?: (value: boolean) => void;
};

const RegisterForm = ({
  visibleSuccessScreen,
  setStep,
  setIsCircleFullScreen,
  setIsLoadingForm,
}: IRegisterFormProperties) => {
  const dispatch = useDispatch<IRootDispatch>();
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { invitation } = useSelector((state: IRootState) => ({
    invitation: state.authStore.invitation,
  }));

  const formik: FormikProps<IRegisterForm> = useFormik<IRegisterForm>({
    enableReinitialize: true,
    initialValues: {
      firstName: invitation?.firstName || '',
      lastName: invitation?.lastName || '',
      workPhone: invitation?.mobile || '',
      workPhoneCountryCode: (invitation?.mobileCountryCode as CountryCode | undefined) ?? 'AU',
      email: invitation?.email || '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => submitForm(),
  });

  const { handleSubmit, handleBlur, values, isValid, dirty, setFieldValue, touched, errors } = formik;

  const showPasswordError = () => {
    if (!touched.password || !errors.password) return;

    if (!errors.password.includes('_')) {
      return [
        {
          status: EValidationStatus.Invalid,
          message: errors.password,
        },
      ];
    }

    return Object.keys(PASSWORD_ERRORS).map((errorKey) => ({
      status: errors.password.includes(errorKey) ? EValidationStatus.Invalid : EValidationStatus.Valid,
      message: PASSWORD_ERRORS[errorKey],
    }));
  };

  const showPassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const showConfirmPassword = () => {
    setVisibleConfirmPassword(!visibleConfirmPassword);
  };

  const submitForm = async () => {
    try {
      setIsSubmitting(true);
      setIsCircleFullScreen(true);
      await dispatch.authStore.doRegister({
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        token: invitation.token,
        serviceProviderId: invitation.serviceProviderId,
        mobile: values.workPhone,
        mobileCountryCode: values.workPhoneCountryCode,
      });
      const result = await dispatch.authStore.doGetLoginToken({});
      if (result && result?.isPortalUser) {
        if (isDesktop) {
          window.location.assign(workspaceRedirectionUrl({ token: result.token }));
        } else {
          visibleSuccessScreen();
        }
      }
    } catch (e) {
      setIsCircleFullScreen(false);
      throw Error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const goToLoginNewTab = () => {
    window.open(loginPath(), '_blank');
  };

  const checkValidateToken = async () => {
    try {
      setIsLoading(true);
      const token = new URLSearchParams(document.location.search).get('token');
      if (token) {
        const data = await dispatch.authStore.doCheckValidateToken({ token });
        if (data?.token) {
          await dispatch.authStore.doSignOutUsers();
        }
      }
      setIsLoading(false);
      setIsLoadingForm(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoadingForm(false);
    }
  };

  useEffect(() => {
    checkValidateToken();
  }, []);

  useEffect(() => {
    setStep(isValid && dirty ? INVITED_STEPS.VALID_INVITED : INVITED_STEPS.START_INVITED);
  }, [isValid, dirty]);

  if (isLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          zIndex: '1',
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{ width: '25%', textAlign: 'center' }} className='anim-fade-in'>
          <div style={{ marginBottom: '16px' }}>
            <Typography.Text style={{ fontSize: '16px' }}>Loading Invitation...</Typography.Text>
          </div>
          <ProgressBar animate={true} />
        </Card>
      </div>
    );
  }

  // doLogin denotes that the user is already registered
  if (invitation?.doLogin) {
    if (invitation.invitationStatus === 'PENDING') {
      // the user is registered, but the invite still needs to be accepted, redirect to accept the invite
      const token = new URLSearchParams(document.location.search).get('token');
      return window.location.assign(`/invitation?token=${token}`);
    }
    return <UnauthorizeMessage />;
  }

  // Error State View
  if (!invitation || isEmpty(invitation) || (invitation && !invitation.isValid)) return <ErrorStateMessage />;

  return (
    <form onSubmit={handleSubmit} className={css(styles.form)()}>
      <Heading level={2} emphasis='regular' marginBottom='$space100'>
        Welcome{' '}
        <Text emphasis='bold' fontSize='inherit !important'>
          {invitation?.firstName}!
        </Text>
      </Heading>
      <Text color='$black' size='medium' marginBottom='$space300'>
        You've been invited to join {invitation?.serviceProviderName} on GoodHuman.
      </Text>
      <InputField label='First name' marginBottom='$space300'>
        <TextField
          name='firstName'
          onChange={(value) => setFieldValue('firstName', value)}
          onBlur={handleBlur}
          value={values.firstName}
          placeholder='Enter first name'
          validations={
            touched.firstName && errors.firstName && [{ status: EValidationStatus.Invalid, message: errors.firstName }]
          }
        />
      </InputField>
      <InputField label='Last name' marginBottom='$space300'>
        <TextField
          name='lastName'
          onChange={(value) => setFieldValue('lastName', value)}
          onBlur={handleBlur}
          value={values.lastName}
          placeholder='Enter last name'
          validations={
            touched.lastName && errors.lastName && [{ status: EValidationStatus.Invalid, message: errors.lastName }]
          }
        />
      </InputField>

      <PhoneInputField
        label='Your work phone'
        marginBottom='$space300'
        combineInput
        validations={
          touched.workPhone && errors.workPhone && [{ message: errors.workPhone, status: EValidationStatus.Invalid }]
        }
        countryCode={values.workPhoneCountryCode}
        onChangeCountryCode={(key: string) => {
          void setFieldValue('workPhoneCountryCode', key);
        }}
        name='workPhone'
        placeholder='Enter work phone'
        onBlur={handleBlur}
        value={values.workPhone}
        onChange={(value: string) => setFieldValue('workPhone', value)}
      />

      {/* <InputField label='Your work phone' marginBottom='$space300'>
        <TextField
          isDisabled
          prefix={
            <Box display='flex'>
              <AustraliaFlag width='$size300' height='$size300' marginRight='$space100' />
              <Text color='$muted' size='small'>
                +61
              </Text>
            </Box>
          }
          name='workPhone'
          placeholder='Enter work phone'
          value={values.workPhone}
          onBlur={handleBlur}
          onChange={(value: string) => setFieldValue('workPhone', value)}
        />
      </InputField> */}

      <InputField label='Email' description='Use the email you were invited with to log in.' marginBottom='$space300'>
        <TextField
          isDisabled
          name='email'
          type='email'
          onChange={(value) => {
            setFieldValue('email', value);
          }}
          onBlur={handleBlur}
          value={values.email}
          placeholder='Enter email here'
          validations={touched.email && errors.email && [{ status: EValidationStatus.Invalid, message: errors.email }]}
        />
      </InputField>
      <InputField label='Password' marginBottom='$space300'>
        <TextField
          name='password'
          onChange={(value) => setFieldValue('password', value)}
          onBlur={handleBlur}
          value={values.password}
          label='Password'
          type={visiblePassword ? 'text' : 'password'}
          placeholder='Enter password here'
          validations={showPasswordError()}
          suffix={
            <span onClick={showPassword} className={css(styles.eyeIcon)()}>
              {visiblePassword ? <EyeClose color='$bodyDark1' /> : <EyeOpen color='$bodyDark1' />}
            </span>
          }
        />
      </InputField>
      <InputField label='Confirm password' marginBottom='$space300'>
        <TextField
          name='confirmPassword'
          onChange={(value) => setFieldValue('confirmPassword', value)}
          onBlur={handleBlur}
          value={values.confirmPassword}
          label='Confirm password'
          type={visibleConfirmPassword ? 'text' : 'password'}
          placeholder='Re-enter password here'
          validations={
            touched.confirmPassword &&
            errors.confirmPassword && [{ status: EValidationStatus.Invalid, message: errors.confirmPassword }]
          }
          suffix={
            <span onClick={showConfirmPassword} className={css(styles.eyeIcon)()}>
              {visibleConfirmPassword ? <EyeClose color='$bodyDark1' /> : <EyeOpen color='$bodyDark1' />}
            </span>
          }
        />
      </InputField>
      <Text size='small' emphasis='regular' color='$bodyDark2' marginTop='$space125' marginBottom='$space400'>
        By creating an account, you agree to GoodHuman’s{' '}
        <Link
          kind='accent'
          underline
          emphasis='bold'
          size='small'
          href='https://goodhuman.me/terms-of-use/'
          target='_blank'
          display='inline'
        >
          Terms of Use
        </Link>
        .
      </Text>
      <Button
        kind='accent'
        emphasis='filled'
        type='submit'
        width='$sizeFull'
        color='$white'
        backgroundColor='$ocean'
        marginTop='auto'
        size='large'
        borderWidth='$border0'
        isDisabled={!(isValid && dirty)}
        isLoading={isSubmitting}
      >
        Create account
      </Button>
      <Text
        size='small'
        emphasis='bold'
        color='$bodyDark2'
        marginTop='$space225'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        Already have an account?
        <Link emphasis='bold' size='small' marginLeft='$space50' onClick={goToLoginNewTab}>
          Log in
        </Link>
      </Text>
    </form>
  );
};

export default RegisterForm;
