import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import { Button, css, Heading, InputField, Link, PhoneInputField, Select, Text, TextField } from 'registration-flow-v2';
import { Info } from 'registration-flow-v2/icon';
import { EValidationStatus } from 'registration-flow-v2/types';
import { SetUpCompanyDetailsFormSchema } from 'utilities/form-utils';
import { COMPANY_SIZE_OPTIONS } from '../constants';

import { Collapse } from '@good/ui/core';
import * as styles from '../css/css-components/CompanyDetailsForm.css';
import { type ICompanyDetailForm } from '../interfaces';
import { STRIPE_CONFIG } from 'variables/app-config';
import { type CountryCode } from 'variables/locales';

interface IProps {
  isLoading: boolean;
  isInvalidABNNumber: boolean;
  onChangeABNErrorState: () => void;
  onNextStep: (values: ICompanyDetailForm) => void;
  onValidStateChange: (isValid: boolean) => void;
  countryCode: CountryCode;
  onChangeCountryCode: (countryCode: CountryCode) => void;
}

const CompanyDetailsForm = ({
  isLoading,
  isInvalidABNNumber,
  onChangeABNErrorState,
  onNextStep,
  onValidStateChange,
  countryCode,
  onChangeCountryCode,
}: IProps) => {
  const formik = useFormik<ICompanyDetailForm>({
    initialValues: {
      workPhone: '',
      companyName: '',
      companySize: '',
      abn: '',
      ndisNumber: '',
      workPhoneCountryCode: 'AU',
    },
    validationSchema: SetUpCompanyDetailsFormSchema,
    onSubmit: onNextStep,
  });

  const { errors, values, touched, dirty, isValid, setFieldValue, handleBlur, validateField, setFieldTouched } = formik;
  const trialPeriodInDays = STRIPE_CONFIG.DEFAULT_TRIAL_PERIOD_IN_DAYS;

  const abnTooltip = (
    <div className={css(styles.tooltipWrapper)()}>
      <Info width='20px' height='20px' minWidth={20} viewBox='0 0 20 20' />
      <div>
        <Heading level={5} color='$white' marginBottom='$space75'>
          Why do we need your ABN?
        </Heading>
        <div className={css(styles.tooltipDescription)()}>
          Your ABN is how we verify your business. It will also be used on any invoices you send.
        </div>
        <Link
          kind='white'
          emphasis='bold'
          underline
          href='https://business.gov.au/registrations/register-for-an-australian-business-number-abn'
          marginTop='$space75'
        >
          Learn more
        </Link>
      </div>
    </div>
  );

  const ndisNumberTooltip = (
    <div className={css(styles.tooltipWrapper)()}>
      <Info width='20px' height='20px' minWidth={20} viewBox='0 0 20 20' />
      <div>
        <Heading level={5} color='$white' marginBottom='$space75'>
          Why do we ask for your NDIS number?
        </Heading>
        <div className={css(styles.tooltipDescription)()}>
          Your NDIS number is how we verify you as an NDIS provider. It will also appear on invoices you send from the
          GoodHuman workspace.
        </div>
        <Link
          kind='white'
          emphasis='bold'
          underline
          href='https://www.ndis.gov.au/providers/becoming-ndis-provider/how-register'
          marginTop='$space75'
        >
          Learn more
        </Link>
      </div>
    </div>
  );

  const getABNErrorsMessage = () => {
    if (touched.abn && errors.abn) return [{ message: errors.abn as string, status: EValidationStatus.Invalid }];

    if (isInvalidABNNumber)
      return [
        {
          message: 'Looks like the number you entered is not a valid ABN. Please check the number and try again.',
          status: EValidationStatus.Invalid,
        },
      ];
  };

  useEffect(
    function syncProgressBarStatus() {
      onValidStateChange(isValid && dirty);
    },
    [dirty, isValid],
  );

  return (
    <form className={css(styles.form)()} onSubmit={formik.handleSubmit}>
      <>
        <Heading level={2} marginBottom='$space300'>
          Enter your company info to start setting up your workspace
        </Heading>
        <PhoneInputField
          label='Your work phone and region'
          marginBottom='$space300'
          combineInput
          validations={
            touched.workPhone && errors.workPhone && [{ message: errors.workPhone, status: EValidationStatus.Invalid }]
          }
          countryCode={values.workPhoneCountryCode}
          onChangeCountryCode={(key: string) => {
            onChangeCountryCode(key as CountryCode);
            void setFieldValue('workPhoneCountryCode', key);
          }}
          name='workPhone'
          placeholder='000 000 000'
          onBlur={handleBlur}
          value={values.workPhone}
          onChange={(value: string) => setFieldValue('workPhone', value)}
        />

        <InputField label='Company name' marginBottom='$space300'>
          <TextField
            name='companyName'
            placeholder='GoodHuman Inc.'
            value={values.companyName}
            validations={
              touched.companyName &&
              errors.companyName && [{ message: errors.companyName as string, status: EValidationStatus.Invalid }]
            }
            onBlur={handleBlur}
            onChange={(value: string) => setFieldValue('companyName', value)}
          />
        </InputField>

        <InputField label='Company size' marginBottom='$space300'>
          <Select
            name='companySize'
            placeholder='2 - 10 employees'
            selectedKey={values.companySize}
            onBlur={(e: React.FocusEvent<Element>) => {
              handleBlur(e);
              setFieldTouched('companySize', true);
              validateField('companySize');
            }}
            onSelectionChange={(key: string) => setFieldValue('companySize', key)}
            validations={
              touched.companySize &&
              errors.companySize && [{ message: errors.companySize as string, status: EValidationStatus.Invalid }]
            }
          >
            {COMPANY_SIZE_OPTIONS.map((item) => (
              <Select.Item key={item.value}>{item.label}</Select.Item>
            ))}
          </Select>
        </InputField>

        <Collapse in={countryCode === 'AU'}>
          <InputField label='ABN' marginBottom='$space300' tooltip={abnTooltip}>
            <TextField
              name='abn'
              type='text'
              placeholder='Enter ABN here'
              value={values.abn}
              validations={getABNErrorsMessage()}
              onBlur={handleBlur}
              onChange={(value: string) => {
                void setFieldValue('abn', value);
                onChangeABNErrorState();
              }}
            />
          </InputField>

          <InputField label='NDIS number' marginBottom='$space175' isRequired={false} tooltip={ndisNumberTooltip}>
            <TextField
              name='ndisNumber'
              placeholder='Enter NDIS number here'
              value={values.ndisNumber}
              onBlur={handleBlur}
              onChange={(value: string) => setFieldValue('ndisNumber', value)}
            />
          </InputField>
        </Collapse>

        <div className={css(styles.description)()}>
          Get started with a free 30-day free trial, you won’t be charged if you cancel before&nbsp;
          <Text emphasis='bold'>{moment().add(trialPeriodInDays, 'days').format('DD MMMM YYYY')}</Text>.
        </div>
      </>

      <Button
        type='submit'
        width='$sizeFull'
        kind='accent'
        emphasis='filled'
        marginTop='auto'
        backgroundColor='$ocean'
        size='large'
        borderWidth='$border0'
        isLoading={isLoading}
        isDisabled={isInvalidABNNumber === undefined ? !(isValid && dirty) : isInvalidABNNumber}
      >
        Next
      </Button>
    </form>
  );
};

export default CompanyDetailsForm;
